export const PATH_NAME = {
    ROOT: '/',
    ERROR_404: '/404',
    ERROR_403: '/403',
    DASHBOARD: '/dashboard',
    LOGIN: '/login',


    //Authentication
    API_AUTH_LOGIN: '/api/auth/login',
    API_AUTH_REGISTER: '/api/auth/register',
    API_AUTH_PROFILE: '/api/auth/profile',

    //Users

    //Admin Coupon Definitions
    API_ADMIN_COUPON_DEFINITION: '/admin-api/couponDefinitions/',
    API_ADMIN_COUPON_DEFINITION_BY_ID: (id: string) => `/admin-api/couponDefinitions/${id}`,

    //Admin Users
    API_ADMIN_USERS: '/admin-api/users/',
    API_ADMIN_USER_BY_ID: (id: string) => `/admin-api/users/${id}`,
    API_ADMIN_USER_GET_MESSAGES:(user_id: number) => `/admin-api/users/get_messages/${user_id}`,
    
    //Admin Roles
    API_ADMIN_ROLES: '/admin-api/roles/',
    API_ADMIN_ROLES_BY_ID: (role_id: string) => `/admin-api/roles/${role_id}`,

    //Admin Permissions
    API_ADMIN_PERMISSIONS: '/admin-api/permissions/',

    //Admin MemberGroups
    API_ADMIN_MEMBER_GROUPS: '/admin-api/member_groups/',
    API_ADMIN_MEMBER_GROUPS_BY_ID: (id: string) => `/admin-api/member_groups/${id}`,

    //Admin Machines
    API_MACHINE: '/admin-api/machines/',

    //Admin User Coupons
    API_ADMIN_USER_COUPONS: '/admin-api/userCoupons/',
    API_ADMIN_USER_COUPONS_BY_ID: (id: string) => `/admin-api/userCoupons/${id}`,

    //Admin Member Group Coupons
    API_MEMBER_GROUP_COUPONS: '/admin-api/member_group_coupons/',
    API_ADMIN_MEMBER_GROUP_COUPONS_BY_ID: (member_group_coupon_id: string) => `/admin-api/member_group_coupons/${member_group_coupon_id}`,

    //Admin RewardPoints
    API_ADMIN_REWARD_POINT: '/admin-api/reward_point/',
    API_ADMIN_REWARD_POINT_BY_ID: (id: string) => `/admin-api/reward_point/${id}`,
    API_GET_REWARD_POINTS_BY_USER_ID: (user_id: number) => `/admin-api/reward_point/get_reward_point_by_user/${user_id}`,
    API_GET_USER_REWARD_POINT_BALANCE:(user_id: number) => `/admin-api/users/get_reward_point_balance/${user_id}`,

    //History Orders
    API_ADMIN_ORDERS:(id:string) => `/admin-api/orders/${id}`,
    API_ADMIN_ORDERS_HISTORY: '/admin-api/orders/history',
    API_ADMIN_GET_ORDER_BY_USER_ID: (user_id: number) => `/admin-api/orders/get_by_user_id?user_id=${user_id}`,

    //History Payment
    API_ADMIN_PAYMENT: '/admin-api/payments/',
    API_ADMIN_PAYMENT_HISTORY: '/admin-api/payments/history',
    API_GET_PAYMENT_ID: (payment_id: string) => `/admin-api/payments/${payment_id}`,
    API_GET_PAYMENT_BY_USER_ID:(user_id: number) => `/admin-api/payments/get_by_user_id/${user_id}`,

    //Admin StoreCredits
    API_GET_STORE_CREDIT_BY_USER_ID:(user_id: number) => `/admin-api/store_credit/get_by_user_id/${user_id}`,
}