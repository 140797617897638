import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../common/DataTable/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useSWR, { useSWRConfig } from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { generalFetcher, generalItemFetcher } from '../../../../helpers/Fetchers/fetchers';
import { deleteUserAPI } from '../../../../api/UserAPI';
import { IUserFull } from '../../../../models/IUser';

const UserTable: React.FC = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();

  const { data: profile } = useSWR(PATH_NAME.API_AUTH_PROFILE, generalFetcher);
  const { data: users = [], error, isLoading } = useSWR(profile ? PATH_NAME.API_ADMIN_USERS : null, generalItemFetcher);

  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const sortedUsers = useMemo(() => {
    return users ? [...users].sort((a: IUserFull, b: IUserFull) => a.id - b.id) : [];
  }, [users]);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 2 },
    { field: 'firstname', headerName: 'First Name', flex: 2 },
    { field: 'lastname', headerName: 'Last Name', flex: 2 },
    { field: 'full_mobile', headerName: 'Phone Number', flex: 2 },
    { field: 'email', headerName: 'Email', flex: 4 },
    {
      field: 'actions', headerName: 'Actions', flex: 1, renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (id: number) => {
    setSelectedUserId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedUserId !== null) {
      try {
        await deleteUserAPI(selectedUserId);
        await mutate(PATH_NAME.API_ADMIN_USERS);
      } catch (error) {
        console.error('Failed to delete role:', error);
      }
    }
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleEdit = (id: string) => {
    navigate(`/System/Users/UserDetails/${id}`);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading Users</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
      </Box>
      <DataTable
        rows={sortedUsers}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
        key={users.length}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this role?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserTable;