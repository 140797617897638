import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button,} from '@mui/material';
import { getUserOrdersAPI } from '../../../../api/OrderAPI';
import { IOrder, UserOrdersProps } from '../../../../models/IOrders';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

const UserOrders: React.FC<UserOrdersProps> = ({ userId }) => {
  const [orders, setOrders] = useState<IOrder[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const data = await getUserOrdersAPI(userId);
        setOrders(data.result.items);
      } catch (error) {
        setError(`Failed to fetch orders for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId]);

  const handleViewClick = (id: number) => {
    navigate(`/order_details/${id}`);
  };

  const handleDeleteConfirm = async () => {
    if (selectedOrderId !== null) {
      try {
        // await deleteOrderAPI(selectedOrderId); // Uncomment once delete function is ready
        // Optionally, refresh orders after deletion
        setOrders(orders?.filter((order) => order.id !== selectedOrderId) || null);
      } catch (error) {
        console.error('Failed to delete order:', error);
      }
    }
    setOpen(false);
    setSelectedOrderId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrderId(null);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="user orders table">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell align="right">Subtotal</TableCell>
            <TableCell align="right">Payment Amount</TableCell>
            <TableCell align="right">Created At</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.length > 0 ? (
            orders.map((order) => (
              <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {order.id}
                </TableCell>
                <TableCell align="right">{order.subtotal}</TableCell>
                <TableCell align="right">{order.payment_amount}</TableCell>
                <TableCell align="right">{new Date(order.created_at).toLocaleDateString()}</TableCell>
                <TableCell align="right">
                  <IconButton aria-label="view" size="small" color="primary" onClick={() => handleViewClick(order.id)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No orders found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default UserOrders;