import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import { OrderAdjustingInputField, AMOUNT_PROPERTY, iniOrderAdjustingInputField } from './OrderAdjustmentDto';

interface UserLayoutPageProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    updateAdjustWallet: (inputField:OrderAdjustingInputField) => void
}

const AdjustWallet: React.FC<UserLayoutPageProps> = ({
    open,
    setOpen,
    updateAdjustWallet
}) => {
    const [inputField, setInputField] = React.useState(iniOrderAdjustingInputField());


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setInputField((prev) => ({
          ...prev,
          [name]: name === AMOUNT_PROPERTY ? parseFloat(value) || 0 : value,
        }));
      };

    const _confirmUpdate = () => {
        updateAdjustWallet(inputField)
        setInputField(iniOrderAdjustingInputField())
        setOpen(false)
    
    }

    const _cancelUpdate = () => {
        setInputField(iniOrderAdjustingInputField())
        setOpen(false)
    }

    const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevent the default scrolling behavior
    };
    
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.addEventListener('wheel', handleWheel as EventListener); // Attach wheel event on focus
    };
    
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        event.currentTarget.removeEventListener('wheel', handleWheel as EventListener); // Remove wheel event on blur
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>{" Adjust Wallet"}</DialogTitle>
            <DialogContent>
      
                <TextField
                    name="description"
                    label="Description"
                    value={inputField.description}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="string"
                    style={{ width: '100%' }}
                />
                <TextField
                    name="amount"
                    label="Amount"
                    value={inputField.amount}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    margin="normal"
                    type="number"
                    style={{ width: '100%' }}
                    onFocus={handleFocus} // Add focus handler
                    onBlur={handleBlur}   // Add blur handler
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => _cancelUpdate()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => _confirmUpdate()} variant="contained" color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default AdjustWallet;


