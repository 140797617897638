import React, { useState } from 'react';
import DataTable from '../../../common/DataTable/DataTable';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useSWR, { useSWRConfig } from 'swr';
import { PATH_NAME } from '../../../../configs/pathName';
import { fetchOrdersAPI } from '../../../../api/OrderAPI';
import { IOrder, IPayment } from '../../../../models/IOrders';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

const OrderTable: React.FC = () => {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();

  const today = dayjs().format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);

  const { data: ordersData, error, isLoading } = useSWR(
    { path: PATH_NAME.API_ADMIN_ORDERS_HISTORY, limit: 1000, offset: 0, start_date: startDate || undefined, end_date: endDate || undefined },
    ({ limit, offset, start_date, end_date }) => fetchOrdersAPI(limit, offset, start_date, end_date)
  );

  const orders = (ordersData?.result?.items.orders || []) as IOrder[];


  const [open, setOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

  const columns = [
    { field: 'user_id', headerName: 'User ID', flex: 1 },
    { field: 'id', headerName: 'Order ID', flex: 1 },
    { field: 'subtotal', headerName: 'Subtotal', flex: 1 },
    {
      field: 'type',
      headerName: 'Payment Methods',
      flex: 1,
      renderCell: (params: any) => {
        if (Array.isArray(params.row.payments) && params.row.payments.length > 0) {
          return (
            <span>
              {params.row.payments.map((payment: IPayment, index: number) => (
                <span key={index}>
                  {payment.type}{index < params.row.payments.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>
          );
        }
        return <span>No payments</span>;
      },
    },
    { field: 'payment_amount', headerName: 'Payment Amount', flex: 1 },
    {
      field: 'created_at', headerName: 'Created At', flex: 2,
      renderCell: (params: any) => dayjs(params.row.created_at).format('DD-MM-YYYY HH:mm'), // Adjust format as needed
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
          >
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
            onClick={() => handleViewClick(params.row.id)} // Handle view click
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
          >
          </IconButton>
          <IconButton
            aria-label="edit"
            size="small"
            color="primary"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            color="primary"
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleViewClick = (id: number) => {
    navigate(`/order_details/${id}`); // Navigate to OrderDetails page with the order ID
  };

  //   const handleEdit = (id: number) => {
  //     navigate(`/Orders/EditOrder/${id}`);
  //   };

  //   const handleCreate = () => {
  //     navigate('/Orders/AddOrder');
  //   };

  const handleDeleteClick = (id: number) => {
    setSelectedOrderId(id);
    setOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedOrderId !== null) {
      try {
        // await deleteOrderAPI(selectedOrderId); // Uncomment once delete function is ready
        await mutate(PATH_NAME.API_ADMIN_ORDERS_HISTORY);
      } catch (error) {
        console.error('Failed to delete order:', error);
      }
    }
    setOpen(false);
    setSelectedOrderId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrderId(null);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading orders</div>;

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, gap: 1 }}>
 

        <TextField
          label="Start Date"
          type="date"
          value={startDate || ''}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate || ''}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
       
      </Box>
      <DataTable
        rows={orders}
        columns={columns}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderTable;
