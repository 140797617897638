import { Container, Typography, Box, Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import useSWR from 'swr';
import { IOrderItem, IPayment } from "../../../../models/IOrders";
import { orderDetailstyle } from "../../../styles/OrderDetails";
import { deductCreditCardAPI, deductWalletAPI, getOrderById } from "../../../../api/OrderAPI";
import { useState } from "react";
import AdjustWallet from "./AdjustWallet";
import AdjustCreditCard from "./AdjustCreditCard";
import { toast } from "../../../common/SnackBar/snackBar";
import { OrderAdjustingInputField } from "./OrderAdjustmentDto";

const formatCurrency = (amount: number | undefined) => `$ ${(amount ?? 0).toFixed(2)}`;

const OrderDetailsPage = () => {
  const { orderId } = useParams<{ orderId?: string }>();
  const [openAdjustWallet, setOpenAdjustWallet] = useState(false);
  const [openAdjustCreditCard, setOpenAdjustCreditCard] = useState(false);
  const { data: orderDetails, error: orderError } = useSWR(
    orderId ? ['getOrderById', orderId] : null,
    () => orderId ? getOrderById(orderId) : null
  );

  if (orderError) return <div>Error loading order details</div>;
  if (!orderDetails) return <div>Loading order details...</div>;

  const {
    subtotal = 0,
    payment_amount = 0,
    items = [],
    payments = []
  } = orderDetails;

  const _updateAdjustWallet = async (inputField: OrderAdjustingInputField) => {
    const payload = {
      customer_id: orderDetails.user_id,
      order_id: orderDetails.id,
      amount: inputField.amount,
      description: inputField.description,
    };
    try {
      const response = await deductWalletAPI(payload);
      toast.success("Adjust Wallet successful!");
      console.log('Wallet deduction response:', response);
    } catch (error) {
      toast.success("Adjust Wallet Fail!");
      console.error('Error deducting wallet:', error);
    }
  };

  const _updateAdjustCreditCard = async (inputField: OrderAdjustingInputField) => {
    const payload = {
      customer_id: orderDetails.user_id,
      order_id: orderDetails.id,
      amount: inputField.amount,
      description: inputField.description,
    };
    try {
      const response = await deductCreditCardAPI(payload);
      toast.success("Adjust Credit Card successful!");
      console.log('Credit card deduction response:', response);
    } catch (error) {
      toast.success("Adjust Credit Card Fail!");
      console.error('Error deducting credit card:', error);
    }
  };

  return (
    <>
      <Container sx={orderDetailstyle.container}>
        <Typography variant="h6" sx={orderDetailstyle.title}>ORDER DETAILS</Typography>

        <Box sx={orderDetailstyle.summaryBox}>
          <Box sx={orderDetailstyle.headerRow}>
            <Typography sx={orderDetailstyle.headerText}>Product</Typography>
            <Typography sx={orderDetailstyle.headerText}>Total</Typography>
          </Box>

          {items.length > 0 ? (
            items.map((item: IOrderItem) => (
              <Box key={item.sku_id} sx={orderDetailstyle.itemRow}>
                <Box sx={orderDetailstyle.itemDetails}>
                  <Typography sx={orderDetailstyle.itemName}>{item.sku_sku}</Typography>
                  <Typography sx={orderDetailstyle.itemQuantity}>× {item.quantity}</Typography>
                </Box>
                <Typography sx={orderDetailstyle.itemPrice}>{formatCurrency(item.item_total)}</Typography>
              </Box>
            ))
          ) : (
            <Typography sx={orderDetailstyle.noItemsText}>No items available for this order.</Typography>
          )}

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.subtotalRow}>
            <Typography sx={orderDetailstyle.subtotalText}>Subtotal:</Typography>
            <Typography sx={orderDetailstyle.subtotalText}>{formatCurrency(subtotal)}</Typography>
          </Box>

          <Box sx={orderDetailstyle.paymentRow}>
          <Typography sx={orderDetailstyle.paymentLabel}>Payment method:</Typography>

            <Typography sx={orderDetailstyle.paymentText}>
              {payments.length > 0 ? (
                payments.map((payment: IPayment, index: number) => (
                  <span key={index}>
                    {payment.type}{index < payments.length - 1 ? ', ' : ''}
                  </span>
                ))
              ) : (
                'N/A'
              )}
            </Typography>
</Box>
        <Box sx={orderDetailstyle.paymentBreakdownBox}>
          {payments.length > 0 ? (
            payments.map((payment: IPayment, index: number) => (
              <Box key={index} sx={orderDetailstyle.paymentBreakdownRow}>
                <Typography sx={orderDetailstyle.paymentType}>{payment.type}:</Typography>
                <Typography sx={orderDetailstyle.paymentAmount}>{formatCurrency(payment.amount)}</Typography>
              </Box>
            ))
          ) : (
            <Typography sx={orderDetailstyle.noPaymentsText}>No payment breakdown available.</Typography>
          )}
        </Box>

          <Divider sx={orderDetailstyle.divider} />

          <Box sx={orderDetailstyle.totalRow}>
            <Typography sx={orderDetailstyle.totalLabel}>TOTAL:</Typography>
            <Typography sx={orderDetailstyle.totalAmount}>{formatCurrency(payment_amount)}</Typography>
          </Box>
        </Box>

        <Box style={{ marginTop: '50px', textAlign: 'right' }} sx={orderDetailstyle.summaryBox}>
          <Button style={{ marginRight: '5px' }} variant="contained" color="primary" onClick={() => setOpenAdjustWallet(true)}> Adjust Wallet
          </Button>
          <Button style={{ marginRight: '5px' }} variant="contained" color="primary" onClick={() => setOpenAdjustCreditCard(true)}> Adjust Credit Card
          </Button>
        </Box>
      </Container>

      <AdjustWallet
        open={openAdjustWallet}
        setOpen={setOpenAdjustWallet}
        updateAdjustWallet={_updateAdjustWallet}
      />

      <AdjustCreditCard
        open={openAdjustCreditCard}
        setOpen={setOpenAdjustCreditCard}
        updateAdjustCreditCard={_updateAdjustCreditCard}
      />

    </>
  );
};

export default OrderDetailsPage;
